import { useEffect } from 'react'
import { useRouter } from 'next/router'
import * as events from '../services/client/events'

const handleRouteChange = () => {
    events.trackPageView()
}

const FacebookPixel: React.FC<React.PropsWithChildren<unknown>> = props => {
    const router = useRouter()

    useEffect(() => {
        // This pageview only trigger first time (it is important for Pixel to have real information)
        handleRouteChange()
        router.events.on('routeChangeComplete', handleRouteChange)
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
        }
    }, [router.events])

    return <>{props.children}</>
}

export default FacebookPixel
