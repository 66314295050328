import 'tailwindcss/tailwind.css'
import App from 'next/app'
import { AuthProvider } from '../contexts/auth'
import { SurveyProvider } from '../contexts/survey'
import { initFirebase } from '../services/client/firebaseClient'
import surveyConfig from '../steps/surveyConfig'
import FacebookPixel from '../components/FacebookPixel'
import { initSentry } from '../services/sentry'
import { appWithTranslation } from 'next-i18next'
import '@fontsource/lato/400.css'
import '@fontsource/lato/700.css'
import '@fontsource/lato/900.css'
import '@fontsource/fredoka-one/400.css'

initSentry()
initFirebase()

class Fastic extends App<{ locale: string }> {
    render() {
        const { Component, pageProps } = this.props
        // Workaround for https://github.com/vercel/next.js/issues/8592
        const err = (this.props as any).err // eslint-disable-line

        return (
            <FacebookPixel>
                <AuthProvider>
                    <SurveyProvider config={surveyConfig}>
                        <Component {...pageProps} err={err} />
                    </SurveyProvider>
                </AuthProvider>
            </FacebookPixel>
        )
    }
}

export default appWithTranslation(Fastic)
